<template>
  <div id="app" class="front-page">
    <div class="scrollToTop circle">
      <i class="fa fa-angle-up"></i>
    </div>

    <div class="page-wrapper">
      <div class="header-container">
        <the-header-top />
        <the-header />
      </div>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
      <the-footer />
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
// import * as VueGoogleMaps from "vue2-google-maps";

import TheHeaderTop from "@/components/TheHeaderTop.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";

// import { data } from "./data/data.js";

export default {
  components: { TheHeaderTop, TheHeader, TheFooter },
  metaInfo: {
    title: "Julide İrgin",
    meta: [
      { name: "author", content: "Deniz İrgin" },
      { "http-equiv": "Content-Type", content: "text/html; charset=utf-8" }
    ]
  },
  mounted() {
    if (window) {
      window.$ = window.jQuery = require("jquery");
      require("bootstrap");
      // require("./plugins/jquery.themepunch.tools.min");
      // require("./plugins/jquery.themepunch.revolution");
      require("imagesloaded");
      require("isotope-layout");
      require("magnific-popup");
      require("./plugins/jquery.waypoints");
      require("./plugins/sticky");
      require("./plugins/jquery.countTo");
      // require("./plugins/slick");
      require("./plugins/template");

      // Vue.use(VueGoogleMaps, {
      //   load: {
      //     key: data.credentials.mapsApiKey,
      //     libraries: "places" // This is required if you use the Autocomplete plugin
      //   }
      // });
    }
  }
};
</script>

<style lang="scss" src="./scss/main.scss"></style>

<style>
p {
  font-family: "Roboto", sans-serif !important;
  font-size: 15px !important;
}

li {
  font-family: "Roboto", sans-serif !important;
  font-size: 15px !important;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.service-content h1.title {
  font-family: "Raleway", sans-serif;
  font-size: 32px;
  font-weight: 500;
  text-transform: none;
}

.service-content-paragraph h1.title {
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-transform: none;
}

.about-me-content h1.title {
  font-family: "Raleway", sans-serif;
  font-size: 32px;
  font-weight: 500;
  text-transform: none;
}

.about-me-content h2.title {
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-transform: none;
}

.about-me-side-bar .armut {
  background-color: #53aa30 !important;
  border-color: #53aa30 !important;
  color: #fff;
}

.about-me-side-bar .armut:hover {
  background-color: #53aa30 !important;
  border-color: #53aa30 !important;
}
.about-me-side-bar .armut:after {
  background-color: #53aa30 !important;
  border-color: #53aa30 !important;
}
.about-me-side-bar .fa-armut::before {
  content: "";
  background-image: url("./assets/white_armut_1@6x-8.png");
  width: 32px;
  height: 32px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: -4px;
}

.contact-side-bar .armut:hover {
  background-color: #53aa30 !important;
  border-color: #53aa30 !important;
}
.contact-side-bar .armut:after {
  background-color: #53aa30 !important;
  border-color: #53aa30 !important;
}

.contact-side-bar .fa-armut::before {
  content: "";
  background-image: url("./assets/gray_armut_1@6x-8.png");
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: -4px;
}

.contact-side-bar .armut:hover .fa-armut::before {
  background-image: url("./assets/white_armut_1@6x-8.png");
}
</style>
