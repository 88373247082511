<template>
  <div class="image-box style-2 mb-20 bordered light-gray-bg">
    <div class="overlay-container overlay-visible">
      <img :src="img" alt itemprop="image" />
      <router-link itemprop="url" class="overlay-link" :to="slug"
        ><i class="fa fa-link"></i
      ></router-link>
    </div>
    <div class="body">
      <h2 itemprop="name">{{ title }}</h2>
      <div class="separator-2"></div>
      <p itemprop="description">{{ desc }}</p>
      <router-link
        itemprop="url"
        class="btn btn-default btn-sm rgin-clear"
        :to="slug"
      >
        Devamını Oku <i class="fa fa-arrow-right pl-10"></i
      ></router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "desc", "img", "slug"]
};
</script>

<style lang="scss" scoped>
h2 {
  text-transform: none !important;
  font-size: 20px;
}
</style>
