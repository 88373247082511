import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";

function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `./views/${view}.vue`);
}

Vue.use(Router);

export function createRouter() {
  return new Router({
    mode: "history",
    base: "/",
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
    routes: [
      {
        path: "/",
        name: "home",
        component: Home
      },
      {
        path: "/hizmetler",
        name: "services",
        component: loadView("Services")
      },
      {
        path: "/hizmetler/:service_slug",
        name: "service",
        component: loadView("Service")
      },
      {
        path: "/hakkimda",
        name: "hakkimda",
        component: loadView("AboutMe")
      },
      {
        path: "/iletisim",
        name: "iletisim",
        component: loadView("Contact")
      }
    ]
  });
}
