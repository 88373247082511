import Vue from "vue";
import EasySlider from "vue-easy-slider";
import Meta from "vue-meta";
import VueAnalytics from "vue-analytics";
import App from "./App.vue";
import { createRouter } from "./router";

Vue.use(EasySlider);
Vue.use(Meta);

export function createApp() {
  const router = createRouter();

  Vue.use(VueAnalytics, { id: "UA-63715668-1", router });

  const app = new Vue({
    router,
    render: h => h(App)
  });

  return { app, router, meta: app.$meta() };
}
