export const data = {
  aboutMe: {
    name: "Jülide İrgin",
    title: "Uzm. Psikolojik Danışman, Aile ve Çift Terapisti",
    metaDesc:
      "Elif Jülide İrgin, Uzm. Psikolojik Danışman, Aile ve Çift Terapisti",
    desc: [
      "2001 yılında Yıldız Teknik Üniversitesi Eğitim Bilimleri Bölümünden mezun oldu. 2013 yılında ise Arel Üniversitesi Psikoloji Yüksek Lisans programını tamamladı. Türkiye Aile ve Evlilik Terapileri Derneği başkanı Dr. Murat Dokur tarafından verilen E.F.T.A onaylı 500 saatlik Aile ve Çift Terapisi Eğitimini tamamladı.",
      "2004 yılından bu yana ilk ve orta dereceli okullarda çocuklarla, gençlere ve aileleriyle çalıştı, bunun yanısıra farklı gelişen çocukların gelişimsel incelenmesi ve takibi, ailelerine yönelik eğitim programlarının geliştirilmesi ve uygulanması konularında çalıştı.",
      "Okul öncesi, okul çağı çocuklarında gelişimsel takip, okula uyum, davranış ve duygusal problemler, gençlerde ergenlik dönemi problemleri ve aile danışmanlığı, yetişkinlerde ise aile ve çift-ilişki problemleri ağırlıklı çalışmaktadır."
    ],
    trainings: [
      "İFTİ Psikoterapi Dr. Murat DOKUR, Aile ve Çift Terapisi",
      "Prof.Rodolfo De Bernart Atölye Çalışması",
      "Mony Elkaim Atölye Çalışması",
      "Kyriaki Polychroni Atölye Çalışması",
      "Prof.Dr Grete LEUTZ Grup Çalışması",
      "Psikodrama Hazırlık Grup Çalışması"
    ],
    testTrainings: [
      "Ankara Gelişim Tarama Envanteri (AGTE)",
      "Goodenough, Harris Bir İnsan Çiz",
      "Bender Gestalt Görsel Motor Algı Testi",
      "Gessel Gelişim Figürleri Testi",
      "C.A.T Çocuklar İçin Tematik Algı Testi",
      "Metropolitan Okul Olgunluğu Testi",
      "Loisa Düss Psikanalitik Hikayeler Testi"
    ],
    associations: ["Türkiye Aile ve Evlilik Terapileri Derneği"],
    phone: "+90 536 296 9448",
    email: "info@julideirgin.com",
    facebook: "https://www.facebook.com/ailevecift",
    linkedin: "https://www.linkedin.com/in/j%C3%BClide-irgin-bekiz-08291b53",
    armutcom: "https://armut.com/p/julide-irgin/225202",
    quotePage: "https://armut.com/Talep-Al-Hizmet-Veren/156-225202",
    addresses: [
      {
        text:
          "Pinum Psikolojik Danışmanlık Merkezi. Sahrayıcedid Mah. Atatürk Cad Karaca Apt no: 27/13",
        coordinates: {
          lat: 40.9841032,
          lng: 29.0585164
        },
        mapsAddress:
          "https://www.google.com/maps/dir//40.9839969,29.0595758/@40.9841032,29.0585164,17z"
      },
      {
        text: "Orta Mah. Bektaş Sk. No:16 D:1 Kartal/İstanbul. Giriş Kat",
        coordinates: {
          lat: 40.9156857,
          lng: 29.2099944
        },
        mapsAddress:
          "https://www.google.com/maps/dir//'40.9156857,29.2099944'/@40.9157351,29.2078379,17z/data=!4m6!4m5!1m0!1m3!2m2!1d29.2099944!2d40.9156857"
      }
    ]
  },
  credentials: {
    mapsApiKey: "AIzaSyDDJptdT2AJkVW3C4-3_QiSSVe0da8MGf0"
  },
  mainService: {
    desc:
      "Aile ve çiftlerle çalışırken, kişilerde ve ilişkilerde sıkıntıya yol açan durumlara bakılır. Kişiler ya da ilişkiler tıkanmışlık ya da sıkışmışlık içersinde olabilir. Aile, birey ya da ilişki canlı bir sistem gibi ele alınarak; kişilerarası eylemsellik, iletişim, psikodinamik ve yapısal özellikleriyle çalışılır.",
    img: require("../assets/family-couple.jpg")
  },
  services: [
    {
      title: "Aile, Çift ve İlişki Terapisi",
      shortDesc:
        "İlk seansa çiftin beraber katılması tercih ve tavsiye edilir, Bu görüşmede; problem tanımı yeniden yapılır, beklentiler ve ihtiyaçlar belirlenir, görüşme sıklığına karar verilir.",
      mainPageImg: require("../assets/family-service.jpeg"),
      servicesPageImg: require("../assets/family-service-large.jpg"),
      metaDesc:
        "Aile ve Çift Terapasi, Çift Terapisi, İlişki Terapisi, Psikolojik Danışmanlık, İlişki Problemleriyle İlgili Danışmanlık, Cinsel Problemlerle İlgili Danışmanlık, Kaygı Bozuklukları",
      slug: "aile-cift-iliski-danismanligi",
      content: [
        {
          subTitle: "Çift ve İlişki Terapisi",
          contentInfo: {
            type: "text",
            content:
              "İlişki içindeki bireylerin sorunlarını net bir biçimde görebilmelerine, sıkıntı yaratan ilişki kalıplarının değiştirilmesine ve yeniden yapılandırılmasına çalışılır. İlişkide problem yaşanan durumlarda çiftlerin problemin çözümüne dair çabaları çoğu zaman problemin bir parçası haline gelmekte ve çiftler bir kısır döngü içinde sıkışmışlık yaşamaktadır. Danışman eşliğinde çiftler, birbirlerine farklı açılardan bakabilmekte, sıkıntı yaratan iletişim özelliklerini görebilmekte ve yeni çözümler üretebilmektedir."
          }
        },
        {
          subTitle: "Aile ve Çift Terapisi",
          contentInfo: {
            type: "text",
            content:
              "Aile ve çift Terapisinda amaç, ailelerin ve/ veya çiftlerin yaşadıkları sıkıntılı süreçleri ele alarak çatışmaların çözülebilmesi ve tüm aile üyelerinin sağlıklı işlev görmelerinin sağlanmasıdır. Hem aile içi ilişkilerin düzenlenmesi hem de diğer insanlar ve durumlar ile ilişkilerin düzenlenmesi hedeflenmektedir. Birbirini etkilemekte ve birbirinden etkilenmekte olan bir sistem olarak aile de; anne baba olarak bir çiftin yaşadığı problem çocuklarının davranış probleminden; çocuklarının davranış problemi ise anne babasının ilişki probleminden bağımsız değildir. Yaşanan sıkıntı, ebeveyn, çocuk ya da çift alt sisteminden veya altsistemlerin birbiriyle ilişkilerinden kaynaklanıyor olabilir. Bu nedenle; aile birbiriyle ilişkili alt sistemleriyle bir arada ele alınarak müdahalede bulunulur."
          }
        },
        {
          subTitle: null,
          contentInfo: {
            type: "text",
            content:
              "İlk seansa çiftin beraber katılması tercih ve tavsiye edilir, Bu görüşmede; problem tanımı yeniden yapılır, beklentiler ve ihtiyaçlar belirlenir, görüşme sıklığına karar verilir. Süreç devam ederken çift ile ayrı ayrı görüşmeler de yapılabilir. Gerek görüldüğünde çiftin çocukları veya diğer aile üyeleri de sürece dâhil edilebilir."
          }
        },
        {
          subTitle:
            "Bu amaçla, çift ve ailelere yönelik şu konularda danışmanlık hizmeti verilmektedir",
          contentInfo: {
            type: "list",
            content: [
              "Evlilik öncesi danışmanlık",
              "İlişki ve çift Terapisi",
              "İlişki problemleri",
              "İletişim problemleri",
              "Aile içi çatışma",
              "Cinsel problemler",
              "Boşanma süreci ve çocuklarla paylaşılması"
            ]
          }
        }
      ]
    },
    {
      title: "Çocuk Odaklı Aile Terapisi",
      shortDesc:
        "İlk görüşmenin Anne-Baba ve çocuk ile birlikte yapılması tercih edilir, çocuk ile ilgili ayrıntılı bilgi alınarak problem tanımı yeniden yapılır ve süreç hakkında bilgi verilir.",
      mainPageImg: require("../assets/Family-Counseling-Puzzle.jpg"),
      servicesPageImg: require("../assets/family-founseling-puzzle-large.gif"),
      metaDesc:
        "Çocuk Terapisi, Çocuk Odaklı Aile Terapisi, Aile Terapisi, Okul Başarısı, Okul sorunları, Uyum Problemleri, Okula Uyum, Okul Fobisi, Dikkat Eksikliği, Dikkat Eksikliği ve Hiperaktivite Bozukluğu, Öğrenme Güçlüğü",
      slug: "cocuk-odakli-aile-danismanligi",
      content: [
        {
          subTitle: null,
          contentInfo: {
            type: "text",
            content:
              "Çocuk gelişimde her yaş dönemi, çocuğun kendini ifade edişi, çevresini anlamlandırması algı ve muhakemesi bakımından farklılıklar gösterir. Her çocuk varolan çevresini ve yaşantısını bir şekilde algılar, kendine geçen duyguyu yorumlar ve kendi penceresini oluşturur. Öte yanda aileye yeni bireyin katılmasıyla birlikte ebeveynlik rolleri de şekillenmeye başlar. Tüm bu süreçler işlerken zaman zaman uyum problemleri, gelişimsel problemler, iletişim sıkıntıları yaşanabilir ve yardım alma ihtiyacı duyulabilir."
          }
        },
        {
          subTitle: null,
          contentInfo: {
            type: "text",
            content:
              "İlk görüşmenin Anne-Baba ve çocuk ile birlikte yapılması tercih edilir, çocuk ile ilgili ayrıntılı bilgi alınarak problem tanımı yeniden yapılır ve süreç hakkında bilgi verilir. Sonraki 2-3 görüşme sadece çocukla yapılır, çocuk ile daha uzun çalışmak, farklı testler uygulamak gerekirse aileye bilgi verilir. Çocukla yapılan çalışmalar tamamlandığında elde edilen bilgiler aileyle paylaşılır ve sürecin nasıl devam edeceğine karar verilir."
          }
        },
        {
          subTitle:
            "Bu amaçla, çocuklara yönelik şu konularda danışmanlık hizmeti verilmektedir",
          contentInfo: {
            type: "list",
            content: [
              "Uyum ve davranış sorunları",
              "İletişim ve kendini ifade etme zorlukları",
              "Aileye yeni bir kardeşin katılması",
              "Kardeş ve arkadaş ilişkilerinde yaşanan problemler",
              "Ebeveynin boşanması",
              "Kardeş kıskançlığı",
              "Yakın kaybı",
              "Anaokulu ve okula başlarken yaşanabilecek problemler",
              "Öğrenme Güçlüğü",
              "Dikkat Eksikliği ve Hiperaktivite",
              "Ders Çalışma alışkanlıkları",
              "Sınav kaygısı",
              "Okul fobosi",
              "Okul başarısı"
            ]
          }
        }
      ]
    },
    {
      title: "Ergen Odaklı Aile Terapisi",
      shortDesc:
        "İlk görüşmenin anne-baba ve genç ile birlikte yapılması tercih edilir. Aile görüşmesinde gencin doğum öncesi, sonrası ve ilk çocukluk dönemiyle ilgili hikâyesi alındıktan sonra, geliş nedeni anne, baba ve genç tarafından ayrı ayrı alınır, problem tanımı yapılır ve süreç hakkında bilgi verilir.",
      mainPageImg: require("../assets/sunkissed-backs-offset-36877.jpg"),
      servicesPageImg: require("../assets/sunkissed-backs-offset-large.png"),
      metaDesc:
        "Madde Bağımlılığı, Ergenle İletişim, Kaygı Bozuklukları, Dikkat Eksikliği, Sosyal Fobi, Uyum Problemleri",
      slug: "ergen-odakli-aile-danismanligi",
      content: [
        {
          subTitle: null,
          contentInfo: {
            type: "text",
            content:
              "Ergenlik, bireyin çocukluk döneminden yetişkinlik dönemine geçerken yaşadığı, hızlı bir büyüme, gelişim ve değişim dönemidir. Bu hızlı değişim dönemine hem çocuğun hem ailenin uyum sağlaması önemlidir. Bu dönemde düşünme, karar verme, muhakeme süreçlerinde değişim ve dönüşüm yaşanır, ergen öfke, coşkunluk, atılganlık ve diğer duygularını kontrol etmekte zorlanabilir. Bazı durumlarda ergendeki bu değişim, aile içi iletişimi, çevre, okul ve arkadaş ilişkilerini olumsuz etkileyebilir. Bu nedenle, anne babalar ve ergenler ne yapabileceği konusunda yardım almaya ihtiyaç duyabilir."
          }
        },
        {
          subTitle: null,
          contentInfo: {
            type: "text",
            content:
              "İlk görüşmenin anne-baba ve genç ile birlikte yapılması tercih edilir. Aile görüşmesinde gencin doğum öncesi, sonrası ve ilk çocukluk dönemiyle ilgili hikâyesi alındıktan sonra, geliş nedeni anne, baba ve genç tarafından ayrı ayrı alınır, problem tanımı yapılır ve süreç hakkında bilgi verilir. Bu görüşmeden sonra genç ile yalnız görüşülür."
          }
        },
        {
          subTitle: null,
          contentInfo: {
            type: "text",
            content:
              "Bazı durumlarda genç destek almayı reddedebilir ve gelmeyebilir. Böyle durumlarda sadece aile ile görüşülerek destek sağlanabilir."
          }
        },
        {
          subTitle:
            "Bu amaçla, ergenlere yönelik şu konularda danışmanlık hizmeti verilmektedir",
          contentInfo: {
            type: "list",
            content: [
              "Yaşanan değişimlere uyum sağlama",
              "Aile içi çatışma",
              "Kurallar/sınırlar",
              "Eğitsel danışmanlık",
              "Okul başarısı",
              "Öfke kontrolü",
              "Kendisine ve çevresine zarar verici davranışlarda bulunma",
              "Arkadaşlık ilişkileri",
              "Meslek seçimi",
              "Sınav kaygısı"
            ]
          }
        }
      ]
    },
    {
      title: "Bireysel Terapi",
      shortDesc:
        "İlk seanslarda, problem tanımı, ihtiyaçlar ve beklentiler üzerinde durulur. görüşme sıklığı belirlenir. Tanımlanan sorun uzmanlık alanı dışında kalıyorsa danışan başka bir uzmana yönlendirebilir.",
      mainPageImg: require("../assets/rsz_no-shortcuts.jpg"),
      servicesPageImg: require("../assets/rsz_no-shortcuts-large.jpg"),
      metaDesc:
        "Ayrılma, Boşanma, Depresyon, Cinsel Problemler, Bağlanma Bozukluğu, İlişki Problemleri",
      slug: "bireysel-danismanlik",
      content: [
        {
          subTitle: null,
          contentInfo: {
            type: "text",
            content:
              "Bireyler yaşantısı boyunca, çevresinde gelişen yaşamsal durumlar ( travma, ilişki, yakın kaybı, ayrılık, hastalık vb) veya yapısal sebeplerle sıkıntı yaşabilmektedir, Bu sıkıntılı durumlar kimi zaman kendiliğinden çözülmekte, kimi zaman da çözülmesi zaman almakta ve kişinin günlük hayatının, işinin, yakın çevresinin, aile ilişkilerinin olumsuz etkilenmesine, kendini sürekli bir kötü hissediş halinde bulmasına sebep olmaktadır. Bu noktada kişiye alacağı yardımla, fonksiyonelliğini geri kazanması ve iyi hissetmesini sağlamak üzere müdahalede bulunulur."
          }
        },
        {
          subTitle: null,
          contentInfo: {
            type: "text",
            content:
              "İlk seanslarda, problem tanımı, ihtiyaçlar ve beklentiler üzerinde durulur. görüşme sıklığı belirlenir. Tanımlanan sorun uzmanlık alanı dışında kalıyorsa danışan başka bir uzmana yönlendirebilir. Eğer danışanın medikal destek alması gerekiyorsa uzman bir psikiyatriste yönlendirilir."
          }
        },
        {
          subTitle:
            "Bu amaçla, bireylere yönelik şu konularda danışmanlık hizmeti verilmektedir",
          contentInfo: {
            type: "list",
            content: [
              "Sosyal, İletişim bozukları",
              "İş yaşamında performans düşüklüğü",
              "Konsantrasyon problemleri",
              "Tekrarlayan olumsuz düşünceler",
              "Korku ve kaygı",
              "Sosyal kaygı",
              "Performans kaygısı",
              "Uyku ve yemek düzeninde bozulma",
              "Kronik yorgunluk",
              "Öfke kontrolü",
              "Günlük yaşamda işlevselliğin bozulması",
              "Aile içi iletişim problemleri",
              "Çift ve ilişki problemleri",
              "Ayrılık süreci"
            ]
          }
        }
      ]
    }
  ]
};
