<template>
  <section
    id="main-service"
    class="main-container padding-bottom-clear"
    itemscope
    itemtype="https://schema.org/PsychologicalTreatment"
  >
    <div class="container">
      <div class="row">
        <div class="main col-12">
          <h1 class="title">Aile ve Çift Terapisi</h1>
          <div class="separator-2 mb-20"></div>
          <div class="row">
            <div class="col-lg-6">
              <p class="desc" itemprop="description">
                {{ mainService.desc }}
              </p>
              <p>
                <router-link
                  class="btn btn-animated btn-info"
                  :to="'/hizmetler/'"
                  itemprop="url"
                >
                  Daha fazla bilgi<i class="fa fa-info-circle"></i
                ></router-link>
              </p>
            </div>
            <aside class="sidebar col-lg-6">
              <div class="block clearfix">
                <img :src="mainService.img" alt itemprop="image" />
                <a class="overlay-link" href="#">
                  <i class="fa fa-link"></i>
                </a>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { data } from "../data/data.js";

export default {
  data() {
    return {
      mainService: data.mainService
    };
  }
};
</script>

<style scoped>
h1.title {
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 500;
  text-transform: none;
}

p.desc {
  font-size: 17px !important;
}
</style>
