import { render, staticRenderFns } from "./HomeMainSlider.vue?vue&type=template&id=f36af7d4&scoped=true&"
import script from "./HomeMainSlider.vue?vue&type=script&lang=js&"
export * from "./HomeMainSlider.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./HomeMainSlider.vue?vue&type=style&index=0&id=f36af7d4&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "f36af7d4",
  "ff0c872a"
  
)

export default component.exports