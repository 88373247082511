<template>
  <section>
    <div class="banner clearfix">
      <div class="slideshow">
        <no-ssr>
          <slider animation="fade" :duration="5000" :speed="1000">
            <slider-item>
              <div
                id="mainpage"
                itemprop="image"
                class="img-container slider-img-2"
                align="center"
              />
            </slider-item>
            <slider-item>
              <div
                id="mainpage"
                itemprop="image"
                class="img-container slider-img-1"
                align="center"
              />
            </slider-item>
            <slider-item>
              <div
                id="mainpage"
                itemprop="image"
                class="img-container slider-img-3"
                align="center"
              />
            </slider-item>
          </slider>
        </no-ssr>
      </div>
    </div>
  </section>
</template>

<script>
import NoSSR from "vue-no-ssr";

export default {
  components: {
    "no-ssr": NoSSR
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 320px) {
  .img-container {
    text-align: center;
    background-size: cover;
    height: 150px;
  }

  .slideshow > div {
    height: 150px !important;
  }
}

@media (min-width: 576px) {
  .img-container {
    text-align: center;
    background-size: cover;
    height: 200px;
  }

  .slideshow > div {
    height: 200px !important;
  }
}

@media (min-width: 768px) {
  .img-container {
    text-align: center;
    background-size: cover;
    height: 350px;
  }

  .slideshow > div {
    height: 350px !important;
  }
}

@media (min-width: 992px) {
  .img-container {
    text-align: center;
    background-size: cover;
    height: 450px;
  }

  .slideshow > div {
    height: 450px !important;
  }
}

@media (min-width: 1200px) {
  .img-container {
    text-align: center;
    background-size: cover;
    height: 550px;
  }

  .slideshow > div {
    height: 550px !important;
  }
}

.slider-img-1 {
  background-image: url(../assets/slider-image1.jpg);
}
.slider-img-2 {
  background-image: url(../assets/slider-image2.jpg);
}
.slider-img-3 {
  background-image: url(../assets/slider-image3.jpg);
}
</style>
