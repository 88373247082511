<template>
  <footer id="footer" class="dark clearfix">
    <div class="subfooter">
      <div class="container">
        <div class="subfooter-inner">
          <div class="row">
            <div class="col-md-12">
              <p class="text-center">
                Copyright © 2019 Jülide İrgin. Her hakkı saklıdır.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>
