<template>
  <header class="header fixed fixed-desktop clearfix">
    <div class="container">
      <div class="row">
        <div class="col-md-auto hidden-md-down">
          <div class="header-first clearfix">
            <div id="logo" class="logo">
              <router-link itemprop="url" to="/">
                <img
                  id="logo_img"
                  src="../assets/logo.png"
                  alt="The Project"
                  width="155"
                  height="64"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-9 ml-auto">
          <div class="header-second clearfix">
            <div class="main-navigation main-navigation--mega-menu animated">
              <nav class="navbar navbar-expand-lg navbar-light p-0">
                <div class="navbar-brand clearfix hidden-lg-up">
                  <div
                    id="logo-mobile"
                    itemscope
                    itemtype="https://schema.org/SiteNavigationElement"
                    class="logo"
                  >
                    <router-link itemprop="url" to="/">
                      <img
                        id="logo-img-mobile"
                        src="../assets/logo.png"
                        width="155"
                        height="64"
                        alt="Aile ve Çift terapisi"
                      />
                    </router-link>
                  </div>
                </div>
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbar-collapse-1"
                  aria-controls="navbar-collapse-1"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div id="navbar-collapse-1" class="collapse navbar-collapse">
                  <!-- main-menu -->
                  <ul
                    class="navbar-nav"
                    itemscope
                    itemtype="https://schema.org/SiteNavigationElement"
                    role="menu"
                  >
                    <li itemprop="name" role="menuitem" class="nav-item">
                      <router-link
                        class="nav-link"
                        to="/"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        itemprop="url"
                        alt="Ana Sayfa"
                        >Ana Sayfa</router-link
                      >
                    </li>
                    <li
                      itemprop="name"
                      role="menuitem"
                      class="nav-item dropdown"
                    >
                      <router-link
                        class="nav-link dropdown-toggle"
                        to="/hizmetler"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        itemprop="url"
                        alt="Hizmetler"
                        >Hizmetler</router-link
                      >
                      <ul class="dropdown-menu">
                        <li
                          v-for="(item, index) in slugs"
                          :key="index"
                          itemprop="name"
                          role="menuitem"
                        >
                          <router-link
                            itemprop="url"
                            :alt="item.title"
                            :to="item.slug"
                            >{{ item.title }}</router-link
                          >
                        </li>
                      </ul>
                    </li>
                    <li itemprop="name" role="menuitem" class="nav-item">
                      <router-link
                        class="nav-link"
                        to="/hakkimda"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        alt="Hakkımda"
                        itemprop="url"
                        >Hakkımda</router-link
                      >
                    </li>
                    <li itemprop="name" role="menuitem" class="nav-item">
                      <router-link
                        class="nav-link"
                        to="/iletisim"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        alt="İletişim"
                        itemprop="url"
                        >İletişim</router-link
                      >
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { data } from "../data/data.js";

export default {
  data() {
    return {
      slugs: []
    };
  },
  mounted() {
    this.slugs = data.services.map(service => {
      return { slug: `/hizmetler/${service.slug}`, title: service.title };
    });
  }
};
</script>
