<template>
  <div class="header-top colored">
    <div class="container">
      <div class="row">
        <div class="col-2 col-md-5">
          <div class="header-top-first clearfix">
            <ul
              itemscope
              itemtype="http://schema.org/Organization"
              class="social-links circle small clearfix hidden-sm-down"
            >
              <link itemprop="url" href="https://www.julideirgin.com" />
              <li class="facebook">
                <a itemprop="sameAs" :href="facebookAddress" alt="Facebook">
                  <i class="fa fa-facebook"></i>
                </a>
              </li>
              <li class="linkedin">
                <a itemprop="sameAs" :href="linkedinAddress" alt="Linkedin">
                  <i class="fa fa-linkedin"></i>
                </a>
              </li>
              <li class="armut">
                <a
                  itemprop="sameAs"
                  title="Armut.com"
                  :href="armutcomAddress"
                  alt="Armut.com"
                >
                  <i class="fa fa-armut"></i>
                </a>
              </li>
            </ul>
            <div class="social-links hidden-md-up circle small">
              <div class="btn-group dropdown">
                <button
                  id="header-top-drop-1"
                  type="button"
                  class="btn dropdown-toggle dropdown-toggle--no-caret"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fa fa-share-alt"></i>
                </button>
                <ul
                  class="dropdown-menu dropdown-animation"
                  aria-labelledby="header-top-drop-1"
                >
                  <li class="facebook">
                    <a itemprop="url" :href="facebookAddress" alt="Facebook">
                      <i class="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li class="linkedin">
                    <a itemprop="url" :href="linkedinAddress" alt="Linkedin">
                      <i class="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li class="armut">
                    <a
                      itemprop="url"
                      title="Armut.com"
                      :href="armutcomAddress"
                      alt="Armut.com"
                    >
                      <i class="fa fa-armut-mobile"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10 col-md-7">
          <!-- header-top-second start -->
          <!-- ================ -->
          <div id="header-top-second" class="clearfix text-right">
            <ul class="list-inline">
              <li class="list-inline-item">
                <i class="fa fa-phone pr-1 pl-10"></i>
                {{ phone }}
              </li>
              <li class="list-inline-item">
                <i class="fa fa-envelope-o pr-1 pl-10"></i>
                {{ email }}
              </li>
            </ul>
          </div>
          <!-- header-top-second end -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { data } from "../data/data.js";

export default {
  data() {
    return {
      facebookAddress: data.aboutMe.facebook,
      linkedinAddress: data.aboutMe.linkedin,
      armutcomAddress: data.aboutMe.armutcom,
      phone: data.aboutMe.phone,
      email: data.aboutMe.email
    };
  }
};
</script>

<style scoped>
.armut a:hover {
  background-color: #53aa30 !important;
  border-color: #53aa30 !important;
}
.fa-armut::before {
  content: "";
  background-image: url("../assets/white_armut_1@6x-8.png");
  width: 12px;
  height: 24px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: -5px;
}
.fa-armut-mobile::before {
  content: "";
  background-image: url("../assets/white_armut_1@6x-8.png");
  width: 12px;
  height: 25px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: -7px;
}
</style>
