<template>
  <div>
    <home-main-slider />
    <div id="page-start"></div>
    <home-main-container />
    <section id="services" class="light-gray-bg pv-30 clearfix">
      <div class="container">
        <div class="row justify-content-lg-center">
          <div class="col-lg-8">
            <h2 class="text-center">
              <strong>HİZMETLER</strong>
            </h2>
            <div class="separator"></div>
          </div>
        </div>
        <div class="row">
          <div
            v-for="service in services"
            :key="service.slug"
            class="col-md-4"
            itemscope
            itemtype="http://schema.org/Service"
          >
            <home-service-container
              :desc="service.shortDesc"
              :title="service.title"
              :img="service.mainPageImg"
              :slug="`/hizmetler/${service.slug}`"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HomeMainSlider from "../components/HomeMainSlider.vue";
import HomeMainContainer from "../components/HomeMainContainer.vue";
import HomeServiceContainer from "../components/HomeServiceContainer.vue";
import { data } from "../data/data.js";

export default {
  components: { HomeMainSlider, HomeMainContainer, HomeServiceContainer },
  metaInfo() {
    return {
      title: "Julide İrgin",
      meta: [
        {
          name: "description",
          content:
            "Aile ve Çift Terapisi, Çift Terapisi, İlişki Terapisi, Çift Danışmanlığı, Psikolojik Danışmanlık, Çocuk, Ergen ve Aile Danışmanlığı, Biresysel Danışmanlık, Boşanma Danışmanlığı alanlarında hizmet verilmektedir"
        },
        {
          property: "og:title",
          content: "Jülide İrgin - Aile ve Çift Terapisi"
        },
        {
          property: "og:site_name",
          content: "Jülide İrgin - Aile ve Çift Terapisi"
        },
        { property: "og:type", content: "website" },
        {
          property: "og:url",
          content: this.fullUrl
        },
        {
          property: "og:image",
          content: data.mainService.img
        },
        {
          property: "og:description",
          content: data.mainService.desc
        },
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:site",
          content: this.fullUrl
        },
        {
          name: "twitter:title",
          content: "Jülide İrgin - Aile ve Çift Terapisi"
        },
        {
          name: "twitter:description",
          content: data.mainService.desc
        },
        {
          name: "twitter:image:src",
          content: data.mainService.img
        }
      ]
    };
  },
  props: {
    services: {
      type: Array,
      default: function() {
        return data.services;
      }
    }
  },
  data() {
    return {
      fullUrl: ""
    };
  },
  created() {
    if (this.$ssrContext && this.$ssrContext.fullUrl) {
      this.fullUrl = this.$ssrContext.fullUrl;
    }
  },
  mounted() {
    if (window) {
      this.fullUrl = window.location.href.toString();
    }
  }
};
</script>
